import React, { ReactNode } from 'react'
import styled from 'styled-components'
import CustomerLogos from '../components/CustomerLogos'
import DemoForm from './form/DemoForm'
import Layout from '../components/Layout'
import FAQWrapper from '../components/FAQWrapper'

interface Props {
  header: ReactNode
  campaign: { value: string; audience: string }
}

const FreeAnalysisLandingPage = ({ header, campaign }: Props) => {
  return (
    <Layout
      justifyCenter
      title="Free DORA and SPACE framework analysis"
      noIndex
    >
      <Container>
        {header}
        <div className="columns">
          <div className="col">
            <Intro audience={campaign.audience} />
          </div>
          <div className="col">
            <DemoForm
              campaign={campaign}
              formId="188fa77c-2a94-4ff4-9b2c-895fadbb4d7c"
            />
          </div>
        </div>
        <CustomerLogos />
      </Container>
      <AnalysisFAQ />
    </Layout>
  )
}

const Intro = ({ audience }: { audience: string }) => {
  return (
    <IntroWrapper>
      <h1>Free DORA metrics and SPACE framework analysis</h1>
      <ContentStyled>
        <IntroStyled>
          As {audience} we offer you a free DORA metrics and SPACE framework
          analysis based on your GitHub data!
        </IntroStyled>
        <p>The analysis includes:</p>
        <ul>
          <li>
            <strong>
              Report with key engineering team productivity metrics
            </strong>{' '}
            like deployment frequency, cycle time, and pull request review
            times.
          </li>
          <li>
            <strong>
              A free 45-minute data walkthrough session with our expert,
            </strong>{' '}
            where we discuss the report findings together and map out{' '}
            <strong>
              your SPACE framework engineering productivity metrics
            </strong>
            .
          </li>
        </ul>
        <p>There is a limited amount of slots for the free analysis.</p>
        <FAQParagraph>
          Want to learn more about the analysis? Check out{' '}
          <a href="#faq">the FAQ</a>.
        </FAQParagraph>
      </ContentStyled>
    </IntroWrapper>
  )
}

const AnalysisFAQ = () => {
  return (
    <FAQWrapper>
      <div id="faq" />
      <details>
        <summary>How do you access my GitHub data?</summary>
        <p>
          After we have received your request we will send you an email with
          instructions on how you can install our application from the GitHub
          marketplace. The application will fetch the metadata about your GitHub
          organization and repositories through GitHub APIs.
        </p>
      </details>
      <details>
        <summary>How long is my data kept?</summary>
        <p>
          The data is kept only while we generate the report and hold the
          walkthrough session with you.
        </p>
        <p>
          All data is deleted after the walkthrough session unless you
          explicitly request us to extend your access.
        </p>
      </details>
      <details>
        <summary>Do I get to keep the analysis results?</summary>
        <p>Yes, you will receive two PDF documents that you are free to use:</p>
        <ol>
          <li>The report with key team productivity metrics</li>
          <li>Your SPACE framework engineering productivity metrics summary</li>
        </ol>
      </details>
      <details>
        <summary>What DORA metrics are included?</summary>
        <p>
          The report includes the &quot;cycle time&quot; and &quot;deployment
          frequency&quot; DORA metrics. &quot;Time to restore services&quot; and
          &quot;change failure rate&quot; DORA metrics are not available through
          GitHub.
        </p>
      </details>
      <details>
        <summary>How do you handle data security?</summary>
        <p>
          Keeping your data secure is our top priority. We don’t store source
          code files and use only metadata about your GitHub organization and
          repositories to run analysis on our side.
        </p>
        <p>We also run internal and external security audits twice a year.</p>
        <p>
          Read more about our commitment to{' '}
          <a href="https://help.swarmia.com/data-security">data security</a>.
        </p>
      </details>
    </FAQWrapper>
  )
}

const IntroWrapper = styled.div`
  max-width: 640px;
  margin: 0 auto;
`

const IntroStyled = styled.p`
  max-width: 500px;
`

const FAQParagraph = styled.p`
  font-size: 14px;
  color: #333;

  a {
    color: #333;
  }
`

const ContentStyled = styled.div`
  max-width: 550px;
`

const Container = styled.div`
  .columns {
    display: flex;
    width: 100%;
    margin: auto;
    margin-bottom: 24px;
    max-width: 1280px;
  }
  .col {
    padding: 0 32px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  padding-bottom: 40px;

  & h3 {
    opacity: 0.5;
    font-weight: 500;
  }

  // Show only one column if mobile
  @media screen and (max-width: 981px) {
    .columns {
      flex-direction: column;
      .col {
        width: 100%;
      }
    }
  }
`

export default FreeAnalysisLandingPage
